<template>
  <div>
    <page-title />
    <b-table
      ref="table"
      :fields="columns"
      :items="itemsProvider"
      :current-page="currentPage"
      :per-page="perPage"
      api-url="/card/votes"
      stacked="sm"
      show-empty
      :tbody-tr-class="markHanging"
      no-local-sorting
    >
      <template #head(alias)="data">
        {{ data.label }}
        <!-- actual filtering is performed against alias -->
        <b-input
          v-model="filter.asin"
          size="sm"
          debounce="300"
          autofocus
        />
      </template>
      <template #cell(actions)="{ item }">
        <b-button
          v-if="!item.isClosed"
          size="sm"
          @click="showCardVote(item.cardId)"
        >
          Revote
        </b-button>
        <b-button
          v-else
          size="sm"
          @click="showCardClosed(item.cardId)"
        >
          <b-icon icon="eye" />
        </b-button>
        <b-button
          v-if="item.isClosed && !item.wasStopped"
          size="sm"
          variant="warning"
          title="Stop the card"
          @click="showStopCard(item.alias, item.cardId)"
        >
          <b-icon icon="exclamation-triangle" />
        </b-button>
      </template>
    </b-table>
    <div>
      <table-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
      />
      <table-limit
        v-model="perPage"
        :total-rows="totalRows"
      />
    </div>

    <b-modal
      id="card-vote"
      :title="card.name"
      size="xl"
      hide-footer
    >
      <b-overlay :show="loading">
        <b-alert
          :show="!!error"
          variant="danger"
          v-text="error"
        />
        <card-vote
          v-if="card.id"
          :card="card"
          :show-revote-msg="false"
        >
          <template v-slot="{ canVote, voteAsin, voteProblem }">
            <b-button
              class="mr-2 mb-2"
              variant="primary"
              :disabled="!canVote"
              @click="vote(voteAsin, voteProblem)"
            >
              <b-spinner
                v-if="voting"
                small
              />
              Vote
            </b-button>
            <b-button
              class="mr-2 mb-2"
              @click="hideCardVote()"
            >
              Cancel
            </b-button>
          </template>
        </card-vote>
      </b-overlay>
    </b-modal>

    <b-modal
      id="card-closed"
      :title="cardClosed.name"
      size="xl"
      ok-only
    >
      <b-overlay :show="loading">
        <b-alert
          :show="!!error"
          variant="danger"
          v-text="error"
        />
        <card-closed :card="cardClosed" />
      </b-overlay>
    </b-modal>
    <b-modal
      id="card-stop"
      :title="stopAlias"
      size="sm"
      :ok-disabled="!stopComment"
      ok-only
      @ok="stopCard"
    >
      <b-overlay :show="loading">
        <b-form-input
          v-model="stopComment"
        />
      </b-overlay>
      <template #modal-ok>
        <b-spinner
          v-if="stopping"
          small
        />
        Stop the card
      </template>
    </b-modal>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle'
import table from '@/mixins/table'
import TablePagination from '@/components/TablePagination'
import TableLimit from '@/components/TableLimit'
import ApiService from '@/ApiService'
import { getRequestErrorText } from '@/utils'
import CardVote from '@/components/CardVote'
import CardClosed from '@/components/CardClosed'

export default {
  name: 'History',
  components: {
    CardClosed,
    PageTitle,
    TablePagination,
    TableLimit,
    CardVote
  },
  mixins: [table],
  data () {
    return {
      baseFields: [
        {
          key: 'alias'
        },
        {
          key: 'youAnswer'
        },
        {
          key: 'correctAnswer'
        },
        {
          key: 'adminComment'
        },
        {
          key: 'actions'
        }
      ],
      filter: {
        asin: '' // actual filtering is performed against alias
      },
      card: {},
      loading: false,
      error: '',
      voting: false,
      cardClosed: {},
      stopAlias: null,
      stopId: null,
      stopComment: null,
      stopping: false
    }
  },
  computed: {
    columns () {
      if (this.$store.state.cardTitleIsHidden === false) {
        const fields = this.baseFields
        fields.splice(1, 0, { key: 'name' })
        return fields
      }
      return this.baseFields
    }
  },
  methods: {
    markHanging (item, type) {
      if (!item || type !== 'row') return
      if (item.isHanging) return 'table-danger'
    },
    showCardVote (id) {
      this.$bvModal.show('card-vote')
      this.fetchCard(id)
    },
    fetchCard (id) {
      this.loading = true
      this.error = ''

      ApiService.get(`/card/${id}`)
        .then(response => {
          this.card = response.data
        })
        .catch(error => {
          this.error = getRequestErrorText(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    hideCardVote () {
      this.$bvModal.hide('card-vote')
    },
    vote (voteAsin, voteProblem) {
      this.voting = true

      ApiService.post(`/card/${this.card.id}/re-vote`, {
        asin: voteAsin,
        problem: voteProblem
      })
        .then(() => {
          this.refresh()
          this.hideCardVote()
        })
        .catch(error => {
          this.$bvToast.toast(getRequestErrorText(error), {
            title: 'Vote error',
            variant: 'danger'
          })
        })
        .finally(() => {
          this.voting = false
        })
    },
    showCardClosed (id) {
      this.$bvModal.show('card-closed')
      this.fetchCardClosed(id)
    },
    fetchCardClosed (id) {
      this.loading = true
      this.error = ''

      ApiService.get(`/card/${id}/closed/view`)
        .then(response => {
          this.cardClosed = response.data
        })
        .catch(error => {
          this.error = getRequestErrorText(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    showStopCard (alias, id) {
      this.stopAlias = alias
      this.stopId = id
      this.$bvModal.show('card-stop')
    },
    stopCard () {
      this.stopping = true

      ApiService.post(`/card/${this.stopId}/stop`, {
        reason: this.stopComment
      })
        .then(() => {
          this.$bvToast.toast('Сообщение отправлено', {
            title: 'Успех',
            variant: 'success'
          })
        })
        .catch(error => {
          this.$bvToast.toast(getRequestErrorText(error), {
            title: 'Ошибка запроса',
            variant: 'danger'
          })
        })
        .finally(() => {
          this.stopping = false
          this.stopAlias = null
          this.stopId = null
          this.stopComment = null
          this.$bvModal.hide('card-stop')
        })
    }
  }
}
</script>
